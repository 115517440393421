import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/head',
    name: 'head',
    component: () => import('../views/HeadView.vue')
  },
  {
    path: '/bottom',
    name: 'bottom',
    component: () => import('../views/BottomView.vue')
  },
  {
    path: '/games',
    name: 'games',
    component: () => import('../views/GamesView.vue')
  },
  {
    path: '/apps',
    name: 'apps',
    component: () => import('../views/AppsView.vue')
  },
  {
    path: '/editorial',
    name: 'editorial',
    component: () => import('../views/EditorialView.vue')
  },
  {
    path: '/live',
    name: 'live',
    component: () => import('../views/LiveView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
