<template>
    <div style="background: #5D55FD;">
        <b-container>
            <!-- 用 section 标签包裹这个区域 -->
            <section>
                <b-row class="py-3">
                    <!-- 第一列，手机端和PC端适应的logo，不垂直居中 -->
                    <b-col cols="12" md="2" class="d-flex align-items-start align-items-center">
                        <img src="@/assets/picture/Logo.png" alt="Logo" class="logo-image">
                    </b-col>
                    <!-- 第二列，手机端和PC端适应的文本，垂直居中 -->
                    <b-col cols="12" md="8" class="d-flex align-items-center justify-content-between mt-2">
                        <!-- Home Column -->
                        <b-col cols="2" class="text-center" @click="goHome">
                            <div
                                style="display: flex; justify-content: center; align-items: center; flex-direction: column; cursor: pointer;">
                                <img src="@/assets/picture/icon_home.png" alt="icon_home">
                                <span :style="{ fontWeight: fontWeights.home }"
                                    style="font-family: Inter; font-size: 14.3px; line-height: 22px; text-align: center; text-underline-position: from-font; text-decoration-skip-ink: none;color: #fff;">
                                    Home
                                </span>
                            </div>
                        </b-col>

                        <!-- Games Column -->
                        <b-col cols="2" class="text-center" @click="goGames">
                            <div
                                style="display: flex; justify-content: center; align-items: center; flex-direction: column; cursor: pointer;">
                                <img src="@/assets/picture/icon_games.png" alt="icon_games">
                                <span :style="{ fontWeight: fontWeights.games }"
                                    style="font-family: Inter; font-size: 14.3px; line-height: 22px; text-align: center; text-underline-position: from-font; text-decoration-skip-ink: none;color: #fff;">
                                    Games
                                </span>
                            </div>
                        </b-col>

                        <!-- Apps Column -->
                        <b-col cols="2" class="text-center" @click="goApps">
                            <div
                                style="display: flex; justify-content: center; align-items: center; flex-direction: column; cursor: pointer;">
                                <img src="@/assets/picture/icon_apps.png" alt="icon_apps">
                                <span :style="{ fontWeight: fontWeights.apps }"
                                    style="font-family: Inter; font-size: 14.3px; line-height: 22px; text-align: center; text-underline-position: from-font; text-decoration-skip-ink: none;color: #fff;">
                                    Apps
                                </span>
                            </div>
                        </b-col>

                        <!-- Editorial Column -->
                        <b-col cols="2" class="text-center" @click="goEditorial">
                            <div
                                style="display: flex; justify-content: center; align-items: center; flex-direction: column; cursor: pointer;">
                                <img src="@/assets/picture/icon_Editorial.png" alt="icon_Editorial">
                                <span :style="{ fontWeight: fontWeights.editorial }"
                                    style="font-family: Inter; font-size: 14.3px; line-height: 22px; text-align: center; text-underline-position: from-font; text-decoration-skip-ink: none;color: #fff;">
                                    Editorial
                                </span>
                            </div>
                        </b-col>

                        <!-- Live Column -->
                        <b-col cols="2" class="text-center" @click="goLive">
                            <div
                                style="display: flex; justify-content: center; align-items: center; flex-direction: column; cursor: pointer;">
                                <img src="@/assets/picture/icon_games.png" alt="icon_games">
                                <span :style="{ fontWeight: fontWeights.live }"
                                    style="font-family: Inter; font-size: 14.3px; line-height: 22px; text-align: center; text-underline-position: from-font; text-decoration-skip-ink: none;color: #fff;">
                                    Live
                                </span>
                            </div>
                        </b-col>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import { BContainer, BRow, BCol } from 'bootstrap-vue-next';

export default {
    data() {
        return {
            // 使用响应式对象来管理每一列的字体粗细
            fontWeights: {
                home: '700',
                games: '400',
                apps: '400',
                editorial: '400',
                live: '400',
            }
        }
    },
    components: {
        BContainer,
        BRow,
        BCol
    },
    methods: {
        goHome() {
            // 先切换字体粗细
            this.toggleFontWeight('home');
            this.$router.push('/');
        },
        goGames() {
            // 先切换字体粗细
            this.toggleFontWeight('games');
            this.$router.push('games');
        },
        goApps() {
            // 先切换字体粗细
            this.toggleFontWeight('apps');
            this.$router.push('apps');
        },
        goEditorial() {
            // 先切换字体粗细
            this.toggleFontWeight('editorial');
            this.$router.push('editorial');
        },
        goLive() {
            // 先切换字体粗细
            this.toggleFontWeight('live');
            this.$router.push('live');
        },
        toggleFontWeight(section) {
            // 将所有字体设置为 '400'
            Object.keys(this.fontWeights).forEach(key => {
                this.fontWeights[key] = '400';
            });

            // 将点击的栏目字体设置为 '700'
            this.fontWeights[section] = '700';
        }
    }
};
</script>

<style scoped>
/* 使用媒体查询，根据不同屏幕尺寸设置图片大小 */
.logo-image {
    max-width: 100%;
    height: auto;
    /* 保持等比例缩放 */
}

/* 针对手机端（max-width: 767px）设置图片最大宽度 */
@media (max-width: 767px) {
    .logo-image {
        width: 40%;
        /* 可以根据需求调整 */
    }
}

/* 针对平板和PC端（min-width: 768px）设置图片最大宽度 */
@media (min-width: 768px) {
    .logo-image {
        width: 100%;
        /* 可以根据需求调整 */
    }
}

/* 顶上字体 */
.custom-text {
    font-family: 'PingFang SC', sans-serif;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    font-size: 20px;
    /* Default font size for larger screens */
}

/* 针对手机端，屏幕宽度小于 768px */
@media (max-width: 767px) {
    .custom-text {
        font-size: 16px;
        /* Change font size for mobile screens */
    }
}
</style>
