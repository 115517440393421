<template>
    <section>
        <div style="width: 100%;background-color: #0D0D0D;">
            <b-container>
                <div class="p-3 p-md-5">
                    <div class="d-none d-lg-block">
                        <div style="display: flex; align-items: center;">
                            <div style="width: 305px;display: flex; flex-direction: column;">
                                <div style="text-align: left;">
                                    <img src="@/assets/picture/logo_small.png" alt="logo_small">
                                </div>
                                <div style="margin-top: 24px;height: 102px;">
                                    <span
                                        style="font-family: PingFang SC; font-size: 12px; font-weight: 400; line-height: 16.8px; letter-spacing: -0.01em; display: block; text-align: left; color: #fff;">
                                        AppHarbor, a top-notch game download site. It hosts a wide array of games,
                                        catering to diverse gamer tastes. The sleek interface enables effortless
                                        downloads. With regular updates and a focus on security, it's the go-to for
                                        premium gaming experiences.
                                    </span>
                                </div>
                            </div>
                            <div style="width: 155px;margin-left: 100px;margin-right: 100px;">
                                <span
                                    style="font-family: Inter;font-size: 14px;font-weight: 700;line-height: 12px;display: block; text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #fff;">
                                    AppHarbor App Store
                                </span>
                                <div style="margin-top: 24px;height: 102px;display: flex; flex-direction: column;">
                                    <span
                                        style="font-family: Inter;font-size: 14px;font-weight: 400;line-height: 12px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #fff;">About
                                        us</span>
                                    <span
                                        style="font-family: Inter;font-size: 14px;font-weight: 400;line-height: 12px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #fff;margin-top: 18px;">Contact
                                        us</span>
                                    <span
                                        style="font-family: Inter;font-size: 14px;font-weight: 400;line-height: 12px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #fff;margin-top: 18px;">FAQs</span>
                                    <span
                                        style="font-family: Inter;font-size: 14px;font-weight: 400;line-height: 12px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #fff;margin-top: 18px;">Support</span>
                                </div>
                            </div>
                            <div style="width: 330px;">
                                <span
                                    style="font-family: Inter;font-size: 14px;font-weight: 700;line-height: 12px;display: block; text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #fff;">
                                    Share To
                                </span>
                                <div style="margin-top: 24px;height: 102px;display: flex; align-items: center;">
                                    <img src="@/assets/picture/icon_chat.png" alt="icon_chat"
                                        style="margin-right: 16px;">
                                    <img src="@/assets/picture/icon_facebook.png" alt="icon_facebook"
                                        style="margin-right: 16px;">
                                    <img src="@/assets/picture/icon_tuite.png" alt="icon_tuite"
                                        style="margin-right: 16px;">
                                    <img src="@/assets/picture/icon_Google.png" alt="icon_Google"
                                        style="margin-right: 16px;">
                                    <img src="@/assets/picture/icon_in.png" alt="icon_in" style="margin-right: 16px;">
                                    <img src="@/assets/picture/icon_pin.png" alt="icon_pin">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-block d-lg-none">
                        <div style="display: flex; flex-direction: column;">
                            <div style="text-align: left;">
                                <img src="@/assets/picture/logo_small.png" alt="logo_small">
                            </div>
                            <span
                                style="font-family: PingFang SC; font-size: 12px; font-weight: 400; line-height: 16.8px; letter-spacing: -0.01em; display: block; text-align: left; color: #fff;margin-top: 20px;">
                                AppHarbor, a top-notch game download site.<br> It hosts a wide array of games,
                                catering to diverse gamer tastes. The sleek interface enables effortless
                                downloads. With regular updates and a focus on security, it's the go-to for
                                premium gaming experiences.
                            </span>
                            <span
                                style="font-family: Inter;font-size: 14px;font-weight: 700;line-height: 12px;display: block; text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #fff;margin-top: 30px;">
                                AppHarbor App Store
                            </span>
                            <span
                                style="font-family: Inter;font-size: 14px;font-weight: 400;line-height: 12px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #fff;margin-top: 16px;">About
                                us</span>
                            <span
                                style="font-family: Inter;font-size: 14px;font-weight: 400;line-height: 12px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #fff;margin-top: 16px;">Contact
                                us</span>
                            <span
                                style="font-family: Inter;font-size: 14px;font-weight: 400;line-height: 12px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #fff;margin-top: 16px;">FAQs</span>
                            <span
                                style="font-family: Inter;font-size: 14px;font-weight: 400;line-height: 12px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #fff;margin-top: 16px;">Support</span>
                            <div style="margin-top: 16px;display: flex; align-items: center;">
                                <img src="@/assets/picture/icon_chat.png" alt="icon_chat" style="margin-right: 16px;">
                                <img src="@/assets/picture/icon_facebook.png" alt="icon_facebook"
                                    style="margin-right: 16px;">
                                <img src="@/assets/picture/icon_tuite.png" alt="icon_tuite" style="margin-right: 16px;">
                                <img src="@/assets/picture/icon_Google.png" alt="icon_Google"
                                    style="margin-right: 16px;">
                                <img src="@/assets/picture/icon_in.png" alt="icon_in" style="margin-right: 16px;">
                                <img src="@/assets/picture/icon_pin.png" alt="icon_pin">
                            </div>
                        </div>
                    </div>
                </div>
            </b-container>
        </div>
    </section>
</template>
<script>
import { BContainer } from 'bootstrap-vue-next';

export default {
    components: {
        BContainer
    }
};
</script>