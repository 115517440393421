<template>
  <div style="background: #F2F2FF;">
    <b-container>
      <section>
        <b-row class="d-flex">
          <!-- 仅在PC端将这两段文本放在一起 -->
          <b-col cols="9" md="9" class="d-flex align-items-start" style="margin-top: 40px;">
            <span class="text-left custom-text">
              Find the best mobile games and apps in Aptoide, your favorite app store!
            </span>
          </b-col>
          <b-col cols="3" md="3" style="margin-top: 60px;">
            <div
              style="width: 70px;height: 16px;background-color: #D05CFA;display: flex; justify-content: center; align-items: center;">
              <span
                style="font-family: PingFang SC;font-size: 10px;font-weight: 400;line-height: 12px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #fff;">
                See All →
              </span>
            </div>
          </b-col>
        </b-row>
      </section>
    </b-container>
    <b-container>
      <section class="row" style="margin-top: 15px;">
        <div class="col-6 col-lg-3 mb-3">
          <div class="py-1"
            style="background-color: #fff;display: flex; justify-content: center; align-items: center; flex-direction: column;">
            <img src="@/assets/picture/Punch_Club_2_Fast_Forwar.png" alt="Punch Club 2_ Fast Forwar" class="home-image">
            <div class="d-none d-xl-block" style="margin-top: 16px;">
              <div style="display: flex; align-items: center;">
                <img src="@/assets/picture/Punch_Club2_Fast Forwar2.png" alt="Punch_Club2_Fast Forwar2">
                <div style="display: flex; flex-direction: column;width: 188px;margin-left: 15px;">
                  <div style="display: flex; align-items: center;">
                    <img src="@/assets/picture/icon_like.png" alt="icon_like">
                    <span
                      style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-left: 6px;">14.1K
                    </span>
                  </div>
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;margin-top: 3px;">Punch
                    Club 2: Fast Forwar</span>
                </div>
              </div>
              <div style="display: flex; justify-content: space-between;margin-top: 16px; margin-bottom: 16px;">
                <div style="display: flex; align-items: center;">
                  <img src="@/assets/picture/icon_ratings.png" alt="icon_ratings">
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-left: 8px;">4.9
                  </span>
                </div>
                <a href="https://en.aptoide.com/download?package_name=com.mxzl.en.google&store_name=aptoide-web&entry_point=bundle&utm_medium=home&utm_content=desktop&utm_source=web&utm_campaign=direct"
                  style="text-decoration: none; color: inherit;">
                  <div
                    style="width: 96px;height: 28px;background: linear-gradient(100.53deg, #D55CFA 1.57%, #5D55FD 100%);border-radius: 8px;display: flex; justify-content: center; align-items: center;">
                    <span style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: center;text-underline-position: from-font;text-decoration-skip-ink: none;
                      color: #fff;">Download</span>
                  </div>
                </a>
              </div>
            </div>
            <div class="d-block d-xl-none" style="margin-top: 12px;">
              <div style="display: flex; align-items: center;">
                <img src="@/assets/picture/Punch_Club2_Fast Forwar2.png" alt="Punch_Club2_Fast Forwar2">
                <div style="display: flex; flex-direction: column;width: 110px;margin-left: 7px;">
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">
                    Punch Club 2:
                  </span>
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">
                    Fast Forwar
                  </span>
                </div>
              </div>
              <div style="display: flex; justify-content: space-between;margin-top: 13px;">
                <div style="display: flex; align-items: center;">
                  <img src="@/assets/picture/icon_ratings.png" alt="icon_ratings">
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-left: 8px;">4.9
                  </span>
                </div>
                <div style="display: flex; align-items: center;">
                  <img src="@/assets/picture/icon_like.png" alt="icon_like">
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-left: 6px;">14.1K
                  </span>
                </div>
              </div>
              <a href="https://en.aptoide.com/download?package_name=com.mxzl.en.google&store_name=aptoide-web&entry_point=bundle&utm_medium=home&utm_content=desktop&utm_source=web&utm_campaign=direct"
                style="text-decoration: none; color: inherit;">
                <div
                  style="width: 100%;display: flex; justify-content: center; align-items: center; flex-direction: column;">
                  <div
                    style="width: 110px;height: 28px;background: linear-gradient(100.53deg, #D55CFA 1.57%, #5D55FD 100%);border-radius: 8px;display: flex; justify-content: center; align-items: center;margin-top: 12px;margin-bottom: 8px;">
                    <span style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: center;text-underline-position: from-font;text-decoration-skip-ink: none;
                      color: #fff;">Download</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-3 mb-3">
          <div class="py-1"
            style="background-color: #fff;display: flex; justify-content: center; align-items: center; flex-direction: column;">
            <img src="@/assets/picture/NFL_Retro_Bowl _25.png" alt="NFL_Retro_Bowl _25" class="home-image">
            <div class="d-none d-xl-block" style="margin-top: 16px;">
              <div style="display: flex; align-items: center;">
                <img src="@/assets/picture/NFL_Retro_Bowl_25_2.png" alt="NFL_Retro_Bowl_25_2">
                <div style="display: flex; flex-direction: column;width: 188px;margin-left: 15px;">
                  <div style="display: flex; align-items: center;">
                    <img src="@/assets/picture/icon_like.png" alt="icon_like">
                    <span
                      style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-left: 6px;">14.1K
                    </span>
                  </div>
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;margin-top: 3px;">NFL
                    Retro Bowl '25</span>
                </div>
              </div>
              <div style="display: flex; justify-content: space-between;margin-top: 16px; margin-bottom: 16px;">
                <div style="display: flex; align-items: center;">
                  <img src="@/assets/picture/icon_ratings.png" alt="icon_ratings">
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-left: 8px;">4.9
                  </span>
                </div>
                <a href="https://en.aptoide.com/download?package_name=com.mxzl.en.google&store_name=aptoide-web&entry_point=bundle&utm_medium=home&utm_content=desktop&utm_source=web&utm_campaign=direct"
                  style="text-decoration: none; color: inherit;">
                  <div
                    style="width: 96px;height: 28px;background: linear-gradient(100.53deg, #D55CFA 1.57%, #5D55FD 100%);border-radius: 8px;display: flex; justify-content: center; align-items: center;">
                    <span style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: center;text-underline-position: from-font;text-decoration-skip-ink: none;
                      color: #fff;">Download</span>
                  </div>
                </a>
              </div>
            </div>
            <div class="d-block d-xl-none" style="margin-top: 12px;">
              <div style="display: flex; align-items: center;">
                <img src="@/assets/picture/NFL_Retro_Bowl_25_2.png" alt="NFL_Retro_Bowl_25_2">
                <div style="display: flex; flex-direction: column;width: 110px;margin-left: 7px;">
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">
                    NFL Retro Bowl
                  </span>
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">
                    '25
                  </span>
                </div>
              </div>
              <div style="display: flex; justify-content: space-between;margin-top: 13px;">
                <div style="display: flex; align-items: center;">
                  <img src="@/assets/picture/icon_ratings.png" alt="icon_ratings">
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-left: 8px;">4.9
                  </span>
                </div>
                <div style="display: flex; align-items: center;">
                  <img src="@/assets/picture/icon_like.png" alt="icon_like">
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-left: 6px;">14.1K
                  </span>
                </div>
              </div>
              <a href="https://en.aptoide.com/download?package_name=com.mxzl.en.google&store_name=aptoide-web&entry_point=bundle&utm_medium=home&utm_content=desktop&utm_source=web&utm_campaign=direct"
                style="text-decoration: none; color: inherit;">
                <div
                  style="width: 100%;display: flex; justify-content: center; align-items: center; flex-direction: column;">
                  <div
                    style="width: 110px;height: 28px;background: linear-gradient(100.53deg, #D55CFA 1.57%, #5D55FD 100%);border-radius: 8px;display: flex; justify-content: center; align-items: center;margin-top: 12px;margin-bottom: 8px;">
                    <span style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: center;text-underline-position: from-font;text-decoration-skip-ink: none;
                      color: #fff;">Download</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-3 mb-3">
          <div class="py-1"
            style="background-color: #fff;display: flex; justify-content: center; align-items: center; flex-direction: column;">
            <img src="@/assets/picture/Toca_Boca_World.png" alt="Toca Boca World" class="home-image">
            <div class="d-none d-xl-block" style="margin-top: 16px;">
              <div style="display: flex; align-items: center;">
                <img src="@/assets/picture/Toca_Boca_World2.png" alt="Toca_Boca_World2">
                <div style="display: flex; flex-direction: column;width: 188px;margin-left: 15px;">
                  <div style="display: flex; align-items: center;">
                    <img src="@/assets/picture/icon_like.png" alt="icon_like">
                    <span
                      style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-left: 6px;">14.1K
                    </span>
                  </div>
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;margin-top: 3px;">Toca
                    Boca World</span>
                </div>
              </div>
              <div style="display: flex; justify-content: space-between;margin-top: 16px; margin-bottom: 16px;">
                <div style="display: flex; align-items: center;">
                  <img src="@/assets/picture/icon_ratings.png" alt="icon_ratings">
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-left: 8px;">4.9
                  </span>
                </div>
                <a href="https://en.aptoide.com/download?package_name=com.fowgames.legendofheroes&store_name=aptoide-web&entry_point=bundle&utm_medium=home&utm_content=desktop&utm_source=web&utm_campaign=apkfy"
                  style="text-decoration: none; color: inherit;">
                  <div
                    style="width: 96px;height: 28px;background: linear-gradient(100.53deg, #D55CFA 1.57%, #5D55FD 100%);border-radius: 8px;display: flex; justify-content: center; align-items: center;">
                    <span style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: center;text-underline-position: from-font;text-decoration-skip-ink: none;
                      color: #fff;">Download</span>
                  </div>
                </a>
              </div>
            </div>
            <div class="d-block d-xl-none" style="margin-top: 12px;">
              <div style="display: flex; align-items: center;">
                <img src="@/assets/picture/Toca_Boca_World2.png" alt="Toca_Boca_World2">
                <div style="display: flex; flex-direction: column;width: 110px;margin-left: 7px;">
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">
                    Toca Boca World
                  </span>
                </div>
              </div>
              <div style="display: flex; justify-content: space-between;margin-top: 13px;">
                <div style="display: flex; align-items: center;">
                  <img src="@/assets/picture/icon_ratings.png" alt="icon_ratings">
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-left: 8px;">4.9
                  </span>
                </div>
                <div style="display: flex; align-items: center;">
                  <img src="@/assets/picture/icon_like.png" alt="icon_like">
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-left: 6px;">14.1K
                  </span>
                </div>
              </div>
              <a href="https://en.aptoide.com/download?package_name=com.fowgames.legendofheroes&store_name=aptoide-web&entry_point=bundle&utm_medium=home&utm_content=desktop&utm_source=web&utm_campaign=apkfy"
                style="text-decoration: none; color: inherit;">
                <div
                  style="width: 100%;display: flex; justify-content: center; align-items: center; flex-direction: column;">
                  <div
                    style="width: 110px;height: 28px;background: linear-gradient(100.53deg, #D55CFA 1.57%, #5D55FD 100%);border-radius: 8px;display: flex; justify-content: center; align-items: center;margin-top: 12px;margin-bottom: 8px;">
                    <span style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: center;text-underline-position: from-font;text-decoration-skip-ink: none;
                      color: #fff;">Download</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-3 mb-3">
          <div class="py-1"
            style="background-color: #fff;display: flex; justify-content: center; align-items: center; flex-direction: column;">
            <img src="@/assets/picture/Crypto_Conflict.png" alt="Crypto Conflict" class="home-image">
            <div class="d-none d-xl-block" style="margin-top: 16px;">
              <div style="display: flex; align-items: center;">
                <img src="@/assets/picture/Crypto_Conflict_2.png" alt="Crypto_Conflict_2">
                <div style="display: flex; flex-direction: column;width: 188px;margin-left: 15px;">
                  <div style="display: flex; align-items: center;">
                    <img src="@/assets/picture/icon_like.png" alt="icon_like">
                    <span
                      style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-left: 6px;">14.1K
                    </span>
                  </div>
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;margin-top: 3px;">Crypto
                    Conflict</span>
                </div>
              </div>
              <div style="display: flex; justify-content: space-between;margin-top: 16px; margin-bottom: 16px;">
                <div style="display: flex; align-items: center;">
                  <img src="@/assets/picture/icon_ratings.png" alt="icon_ratings">
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-left: 8px;">4.9
                  </span>
                </div>
                <a href="https://en.aptoide.com/download?package_name=com.secondleap.rootland&store_name=aptoide-web&entry_point=appstore_appview_header_desktop_v10&utm_medium=appview&utm_content=desktop&utm_source=web&utm_campaign=apkfy"
                  style="text-decoration: none; color: inherit;">
                  <div
                    style="width: 96px;height: 28px;background: linear-gradient(100.53deg, #D55CFA 1.57%, #5D55FD 100%);border-radius: 8px;display: flex; justify-content: center; align-items: center;">
                    <span style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: center;text-underline-position: from-font;text-decoration-skip-ink: none;
                      color: #fff;">Download</span>
                  </div>
                </a>
              </div>
            </div>
            <div class="d-block d-xl-none" style="margin-top: 12px;">
              <div style="display: flex; align-items: center;">
                <img src="@/assets/picture/Crypto_Conflict_2.png" alt="Crypto_Conflict_2">
                <div style="display: flex; flex-direction: column;width: 110px;margin-left: 7px;">
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">
                    Crypto Conflict
                  </span>
                </div>
              </div>
              <div style="display: flex; justify-content: space-between;margin-top: 13px;">
                <div style="display: flex; align-items: center;">
                  <img src="@/assets/picture/icon_ratings.png" alt="icon_ratings">
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-left: 8px;">4.9
                  </span>
                </div>
                <div style="display: flex; align-items: center;">
                  <img src="@/assets/picture/icon_like.png" alt="icon_like">
                  <span
                    style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-left: 6px;">14.1K
                  </span>
                </div>
              </div>
              <a href="https://en.aptoide.com/download?package_name=com.secondleap.rootland&store_name=aptoide-web&entry_point=appstore_appview_header_desktop_v10&utm_medium=appview&utm_content=desktop&utm_source=web&utm_campaign=apkfy"
                style="text-decoration: none; color: inherit;">
                <div
                  style="width: 100%;display: flex; justify-content: center; align-items: center; flex-direction: column;">
                  <div
                    style="width: 110px;height: 28px;background: linear-gradient(100.53deg, #D55CFA 1.57%, #5D55FD 100%);border-radius: 8px;display: flex; justify-content: center; align-items: center;margin-top: 12px;margin-bottom: 8px;">
                    <span style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: center;text-underline-position: from-font;text-decoration-skip-ink: none;
                      color: #fff;">Download</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </b-container>
    <b-container class="px-3 px-lg-0">
      <section>
        <h1 class="mt-3 mt-md-5"
          style="font-family: PingFang SC;font-size: 20px;font-weight: 600;line-height: 24px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">
          Top Downloads This Week</h1>
      </section>
    </b-container>
    <b-container>
      <section class="row" style="margin-top: 15px;">
        <div class="col-12 col-lg-3 mb-3">
          <div class="py-1 px-2"
            style="background-color: #fff;display: flex; justify-content: space-between;border-radius: 6px;">
            <div style="display: flex; align-items: center;">
              <img src="@/assets/picture/Ahead_Emotions_Coach.png" alt="Ahead_Emotions_Coach">
              <div style="display: flex; flex-direction: column; margin-left: 12px;">
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">
                  Ahead: Emotions Coach
                </span>
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 400;line-height: 12px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-top: 5px;">
                  Anger & Anxiety
                </span>
              </div>
            </div>
            <a href="https://mobile-legends.en.aptoide.com/app" target="_blank" rel="noopener noreferrer"
              style="text-decoration: none; color: inherit;">
              <div>
                <img src="@/assets/picture/icon_download.png" alt="icon_download">
              </div>
            </a>
          </div>
        </div>
        <div class="col-12 col-lg-3 mb-3">
          <div class="py-1 px-2"
            style="background-color: #fff;display: flex; justify-content: space-between;border-radius: 6px;">
            <div style="display: flex; align-items: center;">
              <img src="@/assets/picture/TikTok.png" alt="TikTok">
              <div style="display: flex; flex-direction: column; margin-left: 12px;">
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">
                  TikTok
                </span>
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 400;line-height: 12px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-top: 5px;">
                  The Biggest Moments
                </span>
              </div>
            </div>
            <a href="https://instagram.en.aptoide.com/app" target="_blank" rel="noopener noreferrer"
              style="text-decoration: none; color: inherit;">
              <div>
                <img src="@/assets/picture/icon_download.png" alt="icon_download">
              </div>
            </a>
          </div>
        </div>
        <div class="col-12 col-lg-3 mb-3">
          <div class="py-1 px-2"
            style="background-color: #fff;display: flex; justify-content: space-between;border-radius: 6px;">
            <div style="display: flex; align-items: center;">
              <img src="@/assets/picture/Candy_Crush_Soda_Sage.png" alt="Candy_Crush_Soda_Sage">
              <div style="display: flex; flex-direction: column; margin-left: 12px;">
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">
                  Candy Crush Soda Sage
                </span>
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 400;line-height: 12px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-top: 5px;">
                  Blast Sugar! Match 3
                </span>
              </div>
            </div>
            <a href="https://https://messenger.en.aptoide.com/app" target="_blank" rel="noopener noreferrer"
              style="text-decoration: none; color: inherit;">
              <div>
                <img src="@/assets/picture/icon_download.png" alt="icon_download">
              </div>
            </a>
          </div>
        </div>
        <div class="col-12 col-lg-3 mb-3 mt-lg-0 mb-lg-0">
          <div class="py-1 px-2"
            style="background-color: #fff;display: flex; justify-content: space-between;border-radius: 6px;">
            <div style="display: flex; align-items: center;">
              <img src="@/assets/picture/ELOH.png" alt="ELOH">
              <div style="display: flex; flex-direction: column; margin-left: 12px;">
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">
                  ELOH
                </span>
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 400;line-height: 12px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-top: 5px;">
                  Broken Rules APP
                </span>
              </div>
            </div>
            <a href="https://https://messenger.en.aptoide.com/app" target="_blank" rel="noopener noreferrer"
              style="text-decoration: none; color: inherit;">
              <div>
                <img src="@/assets/picture/icon_download.png" alt="icon_download">
              </div>
            </a>
          </div>
        </div>
      </section>
      <section class="row">
        <div class="col-12 col-lg-3 mb-3 mt-lg-0 mb-lg-0">
          <div class="py-1 px-2"
            style="background-color: #fff;display: flex; justify-content: space-between;border-radius: 6px;">
            <div style="display: flex; align-items: center;">
              <img src="@/assets/picture/Carrion.png" alt="Carrion">
              <div style="display: flex; flex-direction: column; margin-left: 12px;">
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">
                  Carrion
                </span>
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 400;line-height: 12px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-top: 5px;">
                  HUNT. CONSUME. GRO
                </span>
              </div>
            </div>
            <a href="https://facebook.en.aptoide.com/app" target="_blank" rel="noopener noreferrer"
              style="text-decoration: none; color: inherit;">
              <div>
                <img src="@/assets/picture/icon_download.png" alt="icon_download">
              </div>
            </a>
          </div>
        </div>
        <div class="col-12 col-lg-3 mb-3">
          <div class="py-1 px-2"
            style="background-color: #fff;display: flex; justify-content: space-between;border-radius: 6px;">
            <div style="display: flex; align-items: center;">
              <img src="@/assets/picture/Test_of_Endurance.png" alt="Test_of_Endurance">
              <div style="display: flex; flex-direction: column; margin-left: 12px;">
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">
                  Test of Endurance
                </span>
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 400;line-height: 12px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-top: 5px;">
                  CSR 2 Multiplay Racing
                </span>
              </div>
            </div>
            <a href="https://smarters-player.en.aptoide.com/app" target="_blank" rel="noopener noreferrer"
              style="text-decoration: none; color: inherit;">
              <div>
                <img src="@/assets/picture/icon_download.png" alt="icon_download">
              </div>
            </a>
          </div>
        </div>
        <div class="col-12 col-lg-3 mb-3">
          <div class="py-1 px-2"
            style="background-color: #fff;display: flex; justify-content: space-between;border-radius: 6px;">
            <div style="display: flex; align-items: center;">
              <img src="@/assets/picture/CARROT_Weather.png" alt="CARROT_Weather">
              <div style="display: flex; flex-direction: column; margin-left: 12px;">
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">
                  CARROT Weather
                </span>
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 400;line-height: 12px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-top: 5px;">
                  Grailr LLC
                </span>
              </div>
            </div>
            <a href="https://swiftkey-keyboard.en.aptoide.com/app" target="_blank" rel="noopener noreferrer"
              style="text-decoration: none; color: inherit;">
              <div>
                <img src="@/assets/picture/icon_download.png" alt="icon_download">
              </div>
            </a>
          </div>
        </div>
        <div class="col-12 col-lg-3 mb-3">
          <div class="py-1 px-2"
            style="background-color: #fff;display: flex; justify-content: space-between;border-radius: 6px;">
            <div style="display: flex; align-items: center;">
              <img src="@/assets/picture/Travel_Match.png" alt="Travel_Match">
              <div style="display: flex; flex-direction: column; margin-left: 12px;">
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">
                  Travel Match™
                </span>
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 400;line-height: 12px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;margin-top: 5px;">
                  Adventure in Match
                </span>
              </div>
            </div>
            <a href="https://mobile-legends.en.aptoide.com/app" target="_blank" rel="noopener noreferrer"
              style="text-decoration: none; color: inherit;">
              <div>
                <img src="@/assets/picture/icon_download.png" alt="icon_download">
              </div>
            </a>
          </div>
        </div>
      </section>
    </b-container>
    <b-container>
      <section class="row" style="margin-top: 15px;">
        <img src="@/assets/picture/img_banner.png" alt="img_banner" class="mt-1 mt-md-5 mx-auto d-block"
          style="width: 100%; height: auto;">
      </section>
    </b-container>
    <b-container class="px-3 px-lg-0">
      <section style="margin-top: 40px;">
        <b-row class="d-flex mt-3 mt-md-5">
          <b-col cols="9" md="9" class="d-flex align-items-center">
            <h1
              style="font-family: PingFang SC;font-size: 20px;font-weight: 600;line-height: 24px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">
              Games</h1>
          </b-col>
          <b-col cols="3" md="3" class="d-flex align-items-center">
            <div
              style="width: 70px;height: 16px;background-color: #D05CFA;display: flex; justify-content: center; align-items: center;">
              <span
                style="font-family: PingFang SC;font-size: 10px;font-weight: 400;line-height: 12px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #fff;">
                See All →
              </span>
            </div>
          </b-col>
        </b-row>
      </section>
    </b-container>
    <b-container>
      <section class="row" style="margin-top: 15px;">
        <div class="col-4 col-lg-1" style="display: flex; flex-direction: column;align-items: center;">
          <div style="border-radius: 8px;">
            <img src="@/assets/picture/Bitcoin_Food_Fight.png" alt="Bitcoin_Food_Fight">
            <div style="width: 94px;display: flex; flex-direction: column;margin-top: 8px;">
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">Bitcoin
                Food</span>
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">Fight</span>
            </div>
          </div>
        </div>
        <div class="col-4 col-lg-1 mx-0 mx-lg-3" style="display: flex; flex-direction: column;align-items: center;">
          <div style="border-radius: 8px;">
            <img src="@/assets/picture/Turbo_84.png" alt="Turbo_84">
            <div style="width: 94px;display: flex; flex-direction: column;margin-top: 8px;">
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">Turbo
                '84</span>
            </div>
          </div>
        </div>
        <div class="col-4 col-lg-1" style="display: flex; flex-direction: column;align-items: center;">
          <div style="border-radius: 8px;">
            <img src="@/assets/picture/Bubble_Bash.png" alt="Bubble_Bash">
            <div style="width: 94px;display: flex; flex-direction: column;margin-top: 8px;">
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">Bubble
                Bash</span>
            </div>
          </div>
        </div>
        <div class="col-4 col-lg-1 mx-0 mx-lg-3 mt-3 mb-3 mt-lg-0 mb-lg-0"
          style="display: flex; flex-direction: column;align-items: center;">
          <div style="border-radius: 8px;">
            <img src="@/assets/picture/CropBytes_Farm.png" alt="CropBytes_Farm">
            <div style="width: 94px;display: flex; flex-direction: column;margin-top: 8px;">
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">CropBytes</span>
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">Farm</span>
            </div>
          </div>
        </div>
        <div class="col-4 col-lg-1 mt-3 mb-3 mt-lg-0 mb-lg-0"
          style="display: flex; flex-direction: column;align-items: center;">
          <div style="border-radius: 8px;">
            <img src="@/assets/picture/Arc8.png" alt="Arc8">
            <div style="width: 94px;display: flex; flex-direction: column;margin-top: 8px;">
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">Arc8</span>
            </div>
          </div>
        </div>
        <div class="col-4 col-lg-1 mx-0 mx-lg-3 mt-3 mb-3 mt-lg-0 mb-lg-0"
          style="display: flex; flex-direction: column;align-items: center;">
          <div style="border-radius: 8px;">
            <img src="@/assets/picture/Monkey_Match_3.png" alt="Monkey_Match_3">
            <div style="width: 94px;display: flex; flex-direction: column;margin-top: 8px;">
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">Monkey</span>
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">Match
                3</span>
            </div>
          </div>
        </div>
        <div class="col-4 col-lg-1" style="display: flex; flex-direction: column;align-items: center;">
          <div style="border-radius: 8px;">
            <img src="@/assets/picture/All_Games.png" alt="All_Games">
            <div style="width: 94px;display: flex; flex-direction: column;margin-top: 8px;">
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">All
                Games</span>
            </div>
          </div>
        </div>
        <div class="col-4 col-lg-1 mx-0 mx-lg-3" style="display: flex; flex-direction: column;align-items: center;">
          <div style="border-radius: 8px;">
            <img src="@/assets/picture/Mix_Game.png" alt="Mix_Game">
            <div style="width: 94px;display: flex; flex-direction: column;margin-top: 8px;">
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">Mix
                Game</span>
            </div>
          </div>
        </div>
        <div class="col-4 col-lg-1" style="display: flex; flex-direction: column;align-items: center;">
          <div style="border-radius: 8px;">
            <img src="@/assets/picture/Shiba_Inu_Eternity_Card.png" alt="Shiba_Inu_Eternity_Card">
            <div style="width: 94px;display: flex; flex-direction: column;margin-top: 8px;">
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">Shiba
                Inu</span>
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">Eternity
                Card</span>
            </div>
          </div>
        </div>
        <div class="col-4 col-lg-1 d-none d-lg-block">
          <div style="display: flex; flex-direction: column;align-items: center;margin-left: 2rem;">
            <div style="border-radius: 8px;">
              <img src="@/assets/picture/Horseplay_Real_Money.png" alt="Horseplay_Real_Money">
              <div style="width: 94px;display: flex; flex-direction: column;margin-top: 8px;">
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">Horseplay</span>
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 500;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">Real
                  Money</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </b-container>
    <b-container class="px-3 px-lg-0">
      <section style="margin-top: 40px;">
        <b-row class="d-flex mt-3 mt-md-5">
          <b-col cols="9" md="9" class="d-flex align-items-center">
            <h1
              style="font-family: PingFang SC;font-size: 20px;font-weight: 600;line-height: 24px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">
              All Apps</h1>
          </b-col>
          <b-col cols="3" md="3" class="d-flex align-items-center">
            <div
              style="width: 70px;height: 16px;background-color: #D05CFA;display: flex; justify-content: center; align-items: center;">
              <span
                style="font-family: PingFang SC;font-size: 10px;font-weight: 400;line-height: 12px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #fff;">
                See All →
              </span>
            </div>
          </b-col>
        </b-row>
      </section>
    </b-container>
    <b-container>
      <section class="row" style="margin-top: 15px;">
        <div class="col-6 col-lg-2">
          <div
            style="display: flex; flex-direction: column;align-items: center;border-radius: 8px;background-color: #fff;">
            <img src="@/assets/picture/Casting_Away.png" alt="Casting_Away" style="width: 100%;height: auto;">
            <div style="display: flex;align-items: center;" class="my-2">
              <img src="@/assets/picture/Casting_Away2.png" alt="Casting_Away2">
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;margin-left: 12px;">Casting
                Away</span>
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-2">
          <div
            style="display: flex; flex-direction: column;align-items: center;border-radius: 8px;background-color: #fff;">
            <img src="@/assets/picture/Thinkrolls.png" alt="Thinkrolls" style="width: 100%;height: auto;">
            <div style="display: flex;align-items: center;" class="my-2">
              <img src="@/assets/picture/Thinkrolls2.png" alt="Thinkrolls2">
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;margin-left: 12px;">Thinkrolls</span>
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-2 my-3 my-lg-0">
          <div
            style="display: flex; flex-direction: column;align-items: center;border-radius: 8px;background-color: #fff;">
            <img src="@/assets/picture/The_Stanley_Parable.png" alt="The_Stanley_Parable"
              style="width: 100%;height: auto;">
            <div style="display: flex;align-items: center;" class="my-2">
              <img src="@/assets/picture/The_Stanley_Parable2.png" alt="The_Stanley_Parable2">
              <div style="display: flex; flex-direction: column;">
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;margin-left: 12px;">The
                  Stanley</span>
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;margin-left: 12px;">Parable</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-2 my-3 my-lg-0">
          <div
            style="display: flex; flex-direction: column;align-items: center;border-radius: 8px;background-color: #fff;">
            <img src="@/assets/picture/imagi.png" alt="imagi" style="width: 100%;height: auto;">
            <div style="display: flex;align-items: center;" class="my-2">
              <img src="@/assets/picture/imagi_2.png" alt="imagi_2">
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;margin-left: 12px;">imagi</span>
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-2">
          <div
            style="display: flex; flex-direction: column;align-items: center;border-radius: 8px;background-color: #fff;">
            <img src="@/assets/picture/June’s_Journey.png" alt="June’s_Journey" style="width: 100%;height: auto;">
            <div style="display: flex;align-items: center;" class="my-2">
              <img src="@/assets/picture/June’s_Journey2.png" alt="June’s_Journey2">
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;margin-left: 12px;">June's
                Journey</span>
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-2">
          <div
            style="display: flex; flex-direction: column;align-items: center;border-radius: 8px;background-color: #fff;">
            <img src="@/assets/picture/Sololearn.png" alt="Sololearn" style="width: 100%;height: auto;">
            <div style="display: flex;align-items: center;" class="my-2">
              <img src="@/assets/picture/Sololearn2.png" alt="Sololearn2">
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 14px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;margin-left: 12px;">Sololearn</span>
            </div>
          </div>
        </div>
      </section>
    </b-container>
    <b-container class="px-3 px-lg-0">
      <section style="margin-top: 40px;">
        <b-row class="d-flex mt-3 mt-md-5">
          <b-col cols="9" md="9" class="d-flex align-items-center">
            <h1
              style="font-family: PingFang SC;font-size: 20px;font-weight: 600;line-height: 24px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">
              Editorial</h1>
          </b-col>
          <b-col cols="3" md="3" class="d-flex align-items-center">
            <div
              style="width: 70px;height: 16px;background-color: #D05CFA;display: flex; justify-content: center; align-items: center;">
              <span
                style="font-family: PingFang SC;font-size: 10px;font-weight: 400;line-height: 12px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #fff;">
                See All →
              </span>
            </div>
          </b-col>
        </b-row>
      </section>
    </b-container>
    <b-container>
      <section class="row" style="margin-top: 15px;">
        <div class="col-12 col-lg-3">
          <div style="border-radius: 8px;background-color: #fff;">
            <img src="@/assets/picture/editorial1.png" alt="editorial1" class="d-none d-lg-block"
              style="width: 100%;height: auto;">
            <img src="@/assets/picture/Editorial_h5-1.png" alt="Editorial_h5-1" class="d-block d-lg-none"
              style="width: 100%;height: auto;">
            <div style="margin: 12px;display: flex; flex-direction: column;">
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">Meet
                the creator</span>
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 400;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;margin-top: 6px;">Carrion
                comes from Phobia Game Studio, a small indie developer </span>
              <div style="display: flex; justify-content: space-between;margin-top: 12px;margin-bottom: 12px;">
                <span
                  style="font-family: PingFang SC;font-size: 12px;font-weight: 400;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;">575,716
                  views</span>
                <span
                  style="font-family: PingFang SC;font-size: 12px;font-weight: 400;line-height: 15px;text-align: right;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;">21/09/2024</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <div style="border-radius: 8px;background-color: #fff;">
            <img src="@/assets/picture/editorial2.png" alt="editorial2" class="d-none d-lg-block"
              style="width: 100%;height: auto;">
            <img src="@/assets/picture/Editorial_h5-2.png" alt="Editorial_h5-2" class="d-block d-lg-none"
              style="width: 100%;height: auto;">
            <div style="margin: 12px;display: flex; flex-direction: column;">
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">Hang out at your island home</span>
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 400;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;margin-top: 6px;">To hook valuable deep-sea fish, open your fishing bag and craft </span>
              <div style="display: flex; justify-content: space-between;margin-top: 12px;margin-bottom: 12px;">
                <span
                  style="font-family: PingFang SC;font-size: 12px;font-weight: 400;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;">575,716
                  views</span>
                <span
                  style="font-family: PingFang SC;font-size: 12px;font-weight: 400;line-height: 15px;text-align: right;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;">21/09/2024</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <div style="border-radius: 8px;background-color: #fff;">
            <img src="@/assets/picture/editorial3.png" alt="editorial3" class="d-none d-lg-block"
              style="width: 100%;height: auto;">
            <img src="@/assets/picture/Editorial_h5-3.png" alt="Editorial_h5-3" class="d-block d-lg-none"
              style="width: 100%;height: auto;">
            <div style="margin: 12px;display: flex; flex-direction: column;">
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">Kids hone their reasoning</span>
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 400;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;margin-top: 6px;">Avokiddo is an award-winning independent studio specializing in </span>
              <div style="display: flex; justify-content: space-between;margin-top: 12px;margin-bottom: 12px;">
                <span
                  style="font-family: PingFang SC;font-size: 12px;font-weight: 400;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;">575,716
                  views</span>
                <span
                  style="font-family: PingFang SC;font-size: 12px;font-weight: 400;line-height: 15px;text-align: right;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;">21/09/2024</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <div style="border-radius: 8px;background-color: #fff;">
            <img src="@/assets/picture/editorial4.png" alt="editorial4" class="d-none d-lg-block"
              style="width: 100%;height: auto;">
            <img src="@/assets/picture/Editorial_h5-4 Tiktok.png" alt="Editorial_h5-4 Tiktok" class="d-block d-lg-none"
              style="width: 100%;height: auto;">
            <div style="margin: 12px;display: flex; flex-direction: column;">
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">Get the Most Out of TikTok</span>
              <span
                style="font-family: PingFang SC;font-size: 14px;font-weight: 400;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;margin-top: 6px;">With TikTok LIVE, watch tarot readings, jam sessions, Get </span>
              <div style="display: flex; justify-content: space-between;margin-top: 12px;margin-bottom: 12px;">
                <span
                  style="font-family: PingFang SC;font-size: 12px;font-weight: 400;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;">575,716
                  views</span>
                <span
                  style="font-family: PingFang SC;font-size: 12px;font-weight: 400;line-height: 15px;text-align: right;text-underline-position: from-font;text-decoration-skip-ink: none;color: #00000073;">21/09/2024</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </b-container>
    <b-container class="px-3 px-lg-0">
      <section style="margin-top: 40px;">
        <b-row class="d-flex mt-3 mt-md-5">
          <b-col cols="9" md="9" class="d-flex align-items-center">
            <h1
              style="font-family: PingFang SC;font-size: 20px;font-weight: 600;line-height: 24px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">
              Live Channel</h1>
          </b-col>
        </b-row>
      </section>
    </b-container>
    <b-container>
      <section class="row pb-3" style="margin-top: 15px;">
        <div class="col-12 col-lg-4 mb-3">
          <div style="padding: 8px;background-color: #fff;border-radius: 8px; display: flex; align-items: center;">
            <img src="@/assets/picture/Live_Channel12.png" alt="Live_Channel12">
            <div style="display: flex; flex-direction: column;margin-left: 12px;height: 108px;">
              <div style="flex: 1;display: flex; align-items: center;">
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">May
                  Game Pro Ti...</span>
              </div>
              <div style="flex: 1;display: flex; align-items: center;">
                <img src="@/assets/picture/icon_eye-filled.png" alt="icon_eye-filled">
                <span
                  style="font-family: Poppins;font-size: 14px;font-weight: 400;line-height: 14px;text-align: center;text-underline-position: from-font;text-decoration-skip-ink: none;margin-left: 8px;">78.4K
                  Viewers</span>
              </div>
              <div style="flex: 1;display: flex; align-items: center;">
                <img src="@/assets/picture/img_user.png" alt="img_user">
                <span
                  style="font-family: Poppins;font-size: 14px;font-weight: 500;line-height: 21px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;margin-left: 8px;">Rishab</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mb-3">
          <div style="padding: 8px;background-color: #fff;border-radius: 8px; display: flex; align-items: center;">
            <img src="@/assets/picture/Live_Channel11.png" alt="Live_Channel11">
            <div style="display: flex; flex-direction: column;margin-left: 12px;height: 108px;">
              <div style="flex: 1;display: flex; align-items: center;">
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">May
                  Game Pro Ti...</span>
              </div>
              <div style="flex: 1;display: flex; align-items: center;">
                <img src="@/assets/picture/icon_eye-filled.png" alt="icon_eye-filled">
                <span
                  style="font-family: Poppins;font-size: 14px;font-weight: 400;line-height: 14px;text-align: center;text-underline-position: from-font;text-decoration-skip-ink: none;margin-left: 8px;">78.4K
                  Viewers</span>
              </div>
              <div style="flex: 1;display: flex; align-items: center;">
                <img src="@/assets/picture/img_user.png" alt="img_user">
                <span
                  style="font-family: Poppins;font-size: 14px;font-weight: 500;line-height: 21px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;margin-left: 8px;">Rishab</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mb-3">
          <div style="padding: 8px;background-color: #fff;border-radius: 8px; display: flex; align-items: center;">
            <img src="@/assets/picture/Live_Channel8.png" alt="Live_Channel8">
            <div style="display: flex; flex-direction: column;margin-left: 12px;height: 108px;">
              <div style="flex: 1;display: flex; align-items: center;">
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">May
                  Game Pro Ti...</span>
              </div>
              <div style="flex: 1;display: flex; align-items: center;">
                <img src="@/assets/picture/icon_eye-filled.png" alt="icon_eye-filled">
                <span
                  style="font-family: Poppins;font-size: 14px;font-weight: 400;line-height: 14px;text-align: center;text-underline-position: from-font;text-decoration-skip-ink: none;margin-left: 8px;">78.4K
                  Viewers</span>
              </div>
              <div style="flex: 1;display: flex; align-items: center;">
                <img src="@/assets/picture/img_user.png" alt="img_user">
                <span
                  style="font-family: Poppins;font-size: 14px;font-weight: 500;line-height: 21px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;margin-left: 8px;">Rishab</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 d-block d-lg-none">
          <div style="padding: 8px;background-color: #fff;border-radius: 8px; display: flex; align-items: center;">
            <img src="@/assets/picture/Live_Channel7.png" alt="Live_Channel7">
            <div style="display: flex; flex-direction: column;margin-left: 12px;height: 108px;">
              <div style="flex: 1;display: flex; align-items: center;">
                <span
                  style="font-family: PingFang SC;font-size: 14px;font-weight: 600;line-height: 15px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;">May
                  Game Pro Ti...</span>
              </div>
              <div style="flex: 1;display: flex; align-items: center;">
                <img src="@/assets/picture/icon_eye-filled.png" alt="icon_eye-filled">
                <span
                  style="font-family: Poppins;font-size: 14px;font-weight: 400;line-height: 14px;text-align: center;text-underline-position: from-font;text-decoration-skip-ink: none;margin-left: 8px;">78.4K
                  Viewers</span>
              </div>
              <div style="flex: 1;display: flex; align-items: center;">
                <img src="@/assets/picture/img_user.png" alt="img_user">
                <span
                  style="font-family: Poppins;font-size: 14px;font-weight: 500;line-height: 21px;text-align: left;text-underline-position: from-font;text-decoration-skip-ink: none;margin-left: 8px;">Rishab</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import { BContainer, BRow, BCol } from 'bootstrap-vue-next';

export default {
  name: 'HomeView',
  components: {
    BContainer,
    BRow,
    BCol
  },
  mounted() {
    document.title = 'App Download Platform - Download Your Favorite Apps';
  }
}
</script>

<style scoped>
/* 使用媒体查询，根据不同屏幕尺寸设置图片大小 */
.home-image {
  max-width: 100%;
  height: auto;
  /* 保持等比例缩放 */
}

/* 针对手机端（max-width: 767px）设置图片最大宽度 */
@media (max-width: 767px) {
  .home-image {
    width: 88%;
    /* 可以根据需求调整 */
  }
}

/* 针对平板和PC端（min-width: 768px）设置图片最大宽度 */
@media (min-width: 768px) {
  .home-image {
    width: 97%;
    /* 可以根据需求调整 */
  }
}
</style>
